import { createWebHistory, createRouter } from "vue-router"
import HomePage from "@/components/pages/home-page/HomePage.vue"
import AppKeys from "@/components/pages/app-keys/AppKeys.vue"
import DabApiDocumentation from "@/components/pages/documentation/DabApi.vue"
import DabApiFilters from "@/components/pages/documentation/DabApiFilters.vue"
import NotFound from "@/components/pages/NotFound.vue"
import LoginCallback  from "@/components/pages/LoginCallback.vue"
import BomboraApi from "@/components/pages/documentation/BomboraApi.vue";
import SignalApiDocumentation from "@/components/pages/documentation/SignalApiDocumentation.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/appkeys",
    name: "AppKeys",
    component: AppKeys
  },
  {
    path: "/docs/bombora",
    name: "BomboraDocumentation",
    component: BomboraApi
  },
  {
    path: "/docs/dab",
    name: "DabApiDocumentation",
    component: DabApiDocumentation
  },
  {
    path: "/docs/dab/filter",
    name: "DabApiFilters",
    component: DabApiFilters  
  },
  {
    path: "/docs/signal",
    name: "SignalApiDocumentation",
    component: SignalApiDocumentation
  },
  {
    path: "/LoginCallback",
    name: "LoginCallback",
    component: LoginCallback
  },
  { path: '/:pathMatch(.*)', component: NotFound }
]

const router = createRouter({
  // History mode, vs Hash mode
  history: createWebHistory(),
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {}
    }
    // The vue router docs are apparently wrong, and say this should be { x: 0, y: 0 }, which doesn't work.
    // https://stackoverflow.com/questions/54535838/scroll-behaviour-vuejs-not-working-properly
    return { left: 0, top: 0 }
  }
})

export default router
