<template>
  <transition>
    <keep-alive>
      <page-container>
        <div class="k-mt-3">
          <alert
            styleType="error"
            v-if="hasAppError"
            @on-dismiss="clearAppError"
          >
            {{ appError }}
          </alert>
          <router-view></router-view>
        </div>
      </page-container>
    </keep-alive>
  </transition>
</template>

<script>
import { mapState } from "vuex"
import PageContainer from "./components/PageContainer.vue"
import { Alert } from "@bombora/component-library/dist/esm/Alert"
import { scrollToAnchor} from "@/utils/document";

export default {
  name: "App",
  components: {
    PageContainer,
    Alert,
  },
  computed: {
    ...mapState(['appError', 'hasAppError']),
  },
  mounted: function() {
    // This is only called when the SPA is initially loaded, which is a case that
    // router.scrollPosition doesn't cover.
    // It ensures that, if the url contains an anchor #, the page scrolls down to that hash.
    if (this.$route.hash) {
      setTimeout(() => scrollToAnchor(this.$route.hash), 1);
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    clearAppError() {
      this.$store.commit("clearAppError")
    }
  },
}
</script>

<style></style>
