<template>
  <div class="px-4 mt-6 mb-6 mr-10">
    <h1 class="mt-12 mb-4 text-2xl font-bold tracking-normal text-blue-dark">
      Your App Key
    </h1>

    <p class="font-semibold pb-14 mb-12 border-b border-gray-light">
      Manage your App Keys and create apps powered by Bombora Company Surge®
      data.
    </p>

    <div v-if="!$auth.loading.value && !$auth.isAuthenticated.value">
      You must login to view this page.
    </div>

    <div
      v-if="
        !$auth.loading.value && !apiCredsLoading && $auth.isAuthenticated.value
      "
    >
      <!-- user is not authorized to access app key -->
      <div v-if="apiCredsUnauthorized">
        <p class="mb-8">
          Your Account currently does not have App Key access. Please contact
          your customer success representative or
          <a href="https://bombora.com/contact-us/" class="text-orange"
            >contact us</a
          >
          to receive access.
        </p>

        <a
          role="button"
          href="https://bombora.com/contact-us/"
          class="btn-orange"
          ><span class="px-8">Contact Us</span></a
        >
      </div>

      <!-- user is authorized but does not have entity app key -->
      <div
        v-if="!apiCredsUnauthorized && !apiCredsExist && !hasAppError"
        class="text-center m-auto"
        style="max-width: 729px;"
      >
        <h2 class="text-blue-dark text-xl font-bold mb-4">
          Whoops! You don't have any App keys yet.
        </h2>
        <p class="mb-8">
          Your Account currently does not have App Key access. Please contact
          your customer success representative or
          <a href="https://bombora.com/contact-us/" class="text-orange"
            >contact us</a
          >
          to receive access.
        </p>

        <a
          role="button"
          href="https://bombora.com/contact-us/"
          class="btn-orange"
          ><span class="px-8">Contact Us</span></a
        >
      </div>

      <!-- user is authorized and has entity app key -->
      <div v-if="!apiCredsUnauthorized && apiCredsExist && !hasAppError">
        <h2
          class="pb-4 font-semibold border-b border-gray-light text-blue-dark text-lg"
        >
          Your App Key
        </h2>
        <card-gray v-if="!apiCredsLoading && apiCreds && !hasAppError">
          <div class="text-sm px-8 py-6">
            <div class="font-bold mb-2">DAB API (v1)</div>

            <div class="flex">
              <div style="width: 50%;">
                <h3>Client ID:</h3>
                <div class="mb-4 flex items-center break-all">
                  {{ apiCreds.client_id }}
                  <button @click="copyId()" class="mx-2"><CopySvg /></button>
                  <span
                    :class="
                      `text-xs whitespace-nowrap ${
                        idIsCopied ? '' : 'invisible'
                      }`
                    "
                    >Copied!</span
                  >
                </div>
              </div>

              <div style="width: 50%;">
                <h3>Secret Key:</h3>
                <button
                  v-if="!showSecret"
                  class="text-orange"
                  @click="toggleSecret()"
                >
                  Reveal
                </button>
                <div v-if="showSecret" class="flex items-start break-all">
                  {{ apiCreds.client_secret }}
                  <button @click="copySecret()" class="mx-2 mt-1">
                    <CopySvg />
                  </button>
                  <span
                    :class="
                      `text-xs whitespace-nowrap ${
                        secretIsCopied ? '' : 'invisible'
                      }`
                    "
                    >Copied!</span
                  >
                </div>
              </div>
            </div>
          </div>
        </card-gray>
      </div>

      <!-- user is authorized but app key request fails -->
      <div v-if="!apiCredsUnauthorized && hasAppError">
        <h2 class="text-blue-dark text-xl font-bold mb-4">
          Whoops! Something went wrong.
        </h2>
        <p class="mb-8">
          Please contact your customer success representative or
          <a href="https://bombora.com/contact-us/" class="text-orange"
            >contact us</a
          >.
        </p>
        <a
          role="button"
          href="https://bombora.com/contact-us/"
          class="btn-orange"
          ><span class="px-8">Contact Us</span></a
        >
      </div>
    </div>

    <div v-if="$auth.loading.value || apiCredsLoading" class="mt-8">
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { copyStringToClipboard } from "../../../utils/document"
import LoadingSpinner from "../../ux/LoadingSpinner"
import CardGray from "../../ux/CardGray.vue"
import CopySvg from "../../svg/CopySvg.vue"
import { mapActions, mapState } from "vuex"

export default {
  name: "HomePage",
  components: {
    CardGray,
    LoadingSpinner,
    CopySvg,
  },
  computed: mapState([
    "apiCreds",
    "apiCredsExist",
    "apiCredsLoading",
    "apiCredsUnauthorized",
    "hasAppError",
  ]),
  data: function() {
    return {
      showSecret: false,
      idIsCopied: false,
      secretIsCopied: false,
      gearPng: require("@/assets/gear.png"),
    }
  },
  mounted() {
    if (this.$auth.isAuthenticated.value) {
      this.getApiCredentials()
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    toggleSecret() {
      this.showSecret = !this.showSecret
    },
    copyId() {
      copyStringToClipboard(this.apiCreds.client_id)
      this.idIsCopied = true
    },
    copySecret() {
      copyStringToClipboard(this.apiCreds.client_secret)
      this.secretIsCopied = true
    },
    ...mapActions(["getApiCredentials"]),
  },
}
</script>
