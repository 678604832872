<template>
  <svg :class="svgClass" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="currentColor"/>
    <circle cx="16" cy="8.15789" r="6.15789" fill="white"/>
    <path d="M16.2309 16.2105C8.47694 16.2105 7.00016 20.6687 7 27.5789H25C25 20.6687 23.9848 16.2105 16.2309 16.2105Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "ProfileSvg",
  props: {
    svgClass: {
      type: String,
      default: "w-5 h-5"
    }
  }
}
</script>

<style scoped></style>
