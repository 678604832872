<template>
  <td class="py-3 px-6 text-left whitespace-nowrap">
    <slot />
  </td>
</template>

<script>
export default {
  name: "TableCell"
}
</script>

<style></style>