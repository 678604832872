<template>
  <div class="flex h-full">
    <div class="m-auto">
      <img src="@/assets/loader.gif" alt="loading..." />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  components: {},
  props: {},
  methods: {}
};
</script>

<style lang="css" scoped></style>
