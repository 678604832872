import {createApp} from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import {setupAuth} from "./services/auth"
import {setupApi} from "./services/api"
import axios from "axios"

import "./assets/base.css"
import {LDPlugin} from "launchdarkly-vue-client-sdk";

function callbackRedirect(appState) {
    router.push(appState && appState.targetUrl ? appState.targetUrl : "/")
}

const main = async () => {
    const res = await axios.get("config/auth")
    if (!res.data.domain || !res.data.clientId || !res.data.audience) {
        console.error(
            "Could not initialize authentication. Missing data.",
            res.data
        )
    }

    let app = createApp(App)
        .use(store)
        .use(router)

    app.use(await setupAuth(
        {
            domain: res.data.domain,
            client_id: res.data.clientId,
            redirect_uri: `${window.location.origin}/LoginCallback`,
            audience: res.data.audience,
        },
        callbackRedirect
    ));

    app.use(await setupApi());

    const ldConfig = (await axios.get("/config/launchdarkly")).data;
    if(!ldConfig || !ldConfig.clientId) {
        console.error("Could not load LaunchDarkly");
    } else {
        app.use(LDPlugin, { clientSideID: ldConfig.clientId });
    }
    
    // Set api on the store so the store can use it in actions.
    // We need to do this because api depends on the token function from the auth0 sdk
    store.$api = app.config.globalProperties.$api;

    return app.mount("#app");
}

main().then((_root) => {
    // console.debug("App Mounted");
});
