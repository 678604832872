<template>
  <div
      class="overflow-hidden bg-gray-lightest">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NavButton",
  props: ["click"],
}
</script>

<style></style>