<template>
  <svg xmlns="http://www.w3.org/2000/svg" :class="svgClass" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
  </svg>
</template>

<script>
export default {
  name: "ChevronUpSvg",
  props: {
    svgClass: {
      type: String,
      default: "h-5 w-5"
    }
  }
}
</script>

<style scoped></style>