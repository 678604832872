<template>
  <th class="py-3 px-6 text-left">
    <slot />
  </th>
</template>

<script>
export default {
  name: "TableHeaderCell"
}
</script>

<style></style>