<template>
  <h1 class="mt-6 text-2xl font-semibold">
    Digital Audience Builder (DAB) Filters
  </h1>
  <router-link to="/docs/dab" class="text-orange mt-2 mb-6 inline-block"
    >Back to DAB Documentation</router-link
  >

  <div v-if="!filterData">
    No filter data was found.
  </div>

  <div v-if="filterData">
    <div v-for="(filter, index) in filterData" :key="index" class="mb-6">
      <collapsible-section
        :id="`list-label-${index}`"
        :label="filter.id"
        button-class="focus:outline-none w-full bg-gray-lightest p-4 border border-gray-lighter rounded"
      >
        <ul
          :aria-labelledby="`list-label-${index}`"
          class="columns py-2 border border-gray-lighter rounded rounded-t-none border-t-0"
        >
          <li
            v-for="(filterValue, index) in filter.values"
            :key="index"
            class="px-4 py-1 leading-5"
          >
            {{ filterValue }}
          </li>
        </ul>
      </collapsible-section>
    </div>
  </div>
</template>

<script>
import filterData from "../../../data/dab-filters.json"
import CollapsibleSection from "@/components/ux/CollapsibleSection"

export default {
  name: "DabApiFiltersPage",
  components: {
    CollapsibleSection,
  },
  data: function() {
    return {
      filterData: filterData.filters,
    }
  },
}
</script>

<style scoped>
.columns {
  -webkit-column-width: 20em;
  -webkit-column-gap: 2em;
  -webkit-column-count: 2;
  -moz-column-width: 20em;
  -moz-column-gap: 2em;
  -moz-column-count: 2;
  column-width: 20em;
  column-gap: 2em;
  column-count: 2;
}

@media (min-width: 1024px) {
  .columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}
</style>
