<!--
Creates a dropdown menu consisting of a toggle button and an absolutely positioned vertical set of elements.

Usage:
<dropdown-menu id="my-menu" :expanded="expanded" @toggle="toggleMenu()" button-class="p-4">
  <template v-slot:toggle>
    <div>My Button</div>
  </template>
  <dropdown-menu-item>Nav Item 1</dropdown-menu-item>
  <dropdown-menu-item>Nav Item 2</dropdown-menu-item>
</dropdown-menu>
-->

<template>
  <div class="relative text-left">
    <button
      @click="toggle()"
      :id="id"
      :aria-expanded="expanded"
      aria-haspopup="true"
      :class="`${buttonClass}`"
    >
      <slot name="toggle" />
    </button>
    <div
      v-if="expanded"
      role="menu"
      aria-orientation="vertical"
      :aria-labelledby="id"
      tabindex="-1"
      class="origin-top-left absolute left-0 bg-white min-w-full"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: {
    id: {
      type: String,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    toggle() {
      this.$emit("toggle")
    },
    close(event) {
      if (!this.$el.contains(event.target) && this.expanded) {
        this.$emit("toggle")
      }
    },
  },
  // These lifecycle events use the close method to collapse the dropdown when
  // a client event is detected outside of the dropdown element.
  mounted() {
    document.addEventListener("click", this.close)
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close)
  },
}
</script>

<style></style>
