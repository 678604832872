<template>
  <div class="overflow-x-auto">
    <div class="w-full lg:w-5/6">
      <div class="bg-white rounded">
        <table class="min-w-max w-full table-auto">
          <slot />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table"
}
</script>

<style></style>