<!--
See the DropdownMenu component for usage.
-->

<template>
  <div role="menuitem" tabindex="-1" class="whitespace-nowrap pl-4 pr-8 py-3 border border-gray-lighter"><slot /></div>
</template>

<script>
export default {
  name: "DropdownMenuItem"
}
</script>

<style></style>