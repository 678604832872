<template>
  <div class="flex">
    <aside
        class="h-screen sticky top-0 flex-shrink-0 w-64 pt-6 h-full border-r border-color-gray-light"
    >
      <h2 class="font-bold px-4 mb-3 text-lg">Documentation</h2>

      <collapsible-section
          id="signal-documentation-nav"
          label="Signal API (V1)"
          button-class="w-full font-bold border-t border-b border-l border-color-gray-light py-5 px-4 text-sm"
          :expanded="true"
      >
        <ul
            aria-labelledby="signal-documentation-nav"
            class="bg-gray-lightest p-4 text-blue-dark text-sm"
        >
          <li class="pt-3">
            <router-link
                :to="{ name: this.$route.name, hash: '#endpoints' }"
                class="font-semibold"
            >Endpoints
            </router-link>
            <ul class="px-6">
              <li class="pt-3">
                <router-link :to="{ name: this.$route.name, hash: '#create-definition' }">Create Signal Definition
                </router-link>
              </li>
              <li class="pt-3">
                <router-link :to="{ name: this.$route.name, hash: '#get-definition' }">Get Signal Definition
                </router-link>
              </li>
              <li class="pt-3">
                <router-link :to="{ name: this.$route.name, hash: '#update-definition' }">Update Signal Definition
                </router-link>
              </li>
              <li class="pt-3">
                <router-link :to="{ name: this.$route.name, hash: '#delete-definition' }">Delete Signal Definition
                </router-link>
              </li>
              <li class="pt-3">
                <router-link :to="{ name: this.$route.name, hash: '#get-definitions' }">Get Signal Definitions
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </collapsible-section>
    </aside>

    <div id="content" class="flex-1 pl-6 min-w-0">

      <h1 class="mt-6 mb-6 text-2xl font-semibold">Signal API</h1>
      <p class="mb-4">
        The Signal API provides customers the ability to programmatically retrieve Company Surge&reg; data.
      </p>


      <div class="mb-4 pb-4 border-b border-gray-lighter">
        <p class="text-sm">
          Jump to:
          <router-link
              :to="{ name: this.$route.name, hash: '#endpoints' }"
              class="text-orange"
          >Endpoints
          </router-link>
        </p>
      </div>

      <h2 class="font-bold text-xl mb-4" id="endpoints">
        <router-link :to="{ name: this.$route.name, hash: '#endpoints' }">Endpoints</router-link>
      </h2>
      <div class="mb-4 pb-2">
        <p class="text-sm">
          Jump to:
          <router-link :to="{ name: this.$route.name, hash: '#create-definition' }" class="text-orange">Create
          </router-link>
          |
          <router-link :to="{ name: this.$route.name, hash: '#get-definition' }" class="text-orange">Get</router-link>
          |
          <router-link :to="{ name: this.$route.name, hash: '#update-definition' }" class="text-orange">Update
          </router-link>
          |
          <router-link :to="{ name: this.$route.name, hash: '#delete-definition' }" class="text-orange">Delete
          </router-link>
          |
          <router-link :to="{ name: this.$route.name, hash: '#get-definitions' }" class="text-orange">Get All
          </router-link>
          |
        </p>
      </div>


      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="create-definition">
          <router-link :to="{ name: this.$route.name, hash: '#create-definition' }">Create Signal Definition</router-link>
        </h3>
        <p class="mb-2">Creates a new signal definition given one or more data points.</p>
        <div class="mb-4">
          <h4 class="font-semibold">Request Path</h4>
          <p>
            POST
            <code class="bg-gray-lightest">/signal/v1/signal-definition</code>
          </p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold">Request Body</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>metadata</dp-table-cell>
                <dp-table-cell>Metadata</dp-table-cell>
                <dp-table-cell>Metadata associated with this signal definition</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>domain</dp-table-cell>
                <dp-table-cell>String</dp-table-cell>
                <dp-table-cell>The primary domain of the company</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>keywords</dp-table-cell>
                <dp-table-cell>String[]</dp-table-cell>
                <dp-table-cell>A collection of relevant keywords</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>homepageUrls</dp-table-cell>
                <dp-table-cell>String[]</dp-table-cell>
                <dp-table-cell>A collection of homepage URLs relating to the company</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>productUrls</dp-table-cell>
                <dp-table-cell>String[]</dp-table-cell>
                <dp-table-cell>A collection of URLs relating to the product or service</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>

          <h3 class="font-semibold pt-3">Metadata</h3>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>name</dp-table-cell>
                <dp-table-cell>String</dp-table-cell>
                <dp-table-cell>A name for this signal definition (max 255)</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>description</dp-table-cell>
                <dp-table-cell>String</dp-table-cell>
                <dp-table-cell>A description for this signal (max 2048)</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="mb-4 font-semibold">Request Body Example</h4>
          <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
	"metadata": {
		"name": "Signal definition for X product",
		"description": "Definition created by Joe for the sales team."
	},
	"domain": "example.com",
	"keywords": [
		"manufacturing",
		"communications"
	],
	"productUrls": [
		"https://www.example.com/productUrl",
		"https://www.example.com/resources/productTraining.html"
	]
 }

        </code></pre>
        </div>

        <div class="mb-4">
          <h4 class="mb-2 font-semibold">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>202</dp-table-cell>
                <dp-table-cell>Accepted</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>400</dp-table-cell>
                <dp-table-cell>Bad Request</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>422</dp-table-cell>
                <dp-table-cell>Unprocessable Entity</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request has been accepted but is not yet processed</dt>
          <dd class="mb-4">
            <div>Response Status: 202</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd class="text-sm bg-gray-lightest">
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "signalDefinitionId": "7030eb5a-311a-4608-b901-8e5d61aad86b"
}</code></pre>
              </dd>
            </dl>
          </dd>

          <dt class="font-semibold">Request was not formatted properly</dt>
          <dd class="mb-4">Response Status: 400 Bad Request</dd>

          <dt class="font-semibold">Request’s token was either invalid or missing</dt>
          <dd class="mb-4">Response Status: 401 Unauthorized</dd>

          <dt class="font-semibold">Request’s token does not contain the required permissions</dt>
          <dd class="mb-4">Response Status: 403 Forbidden</dd>

          <dt class="font-semibold">Request was invalid</dt>
          <dd class="mb-4">
            <div>Response Status: 422</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd class="text-sm bg-gray-lightest">
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "errors": {
    "Domain": ["Domain provided is not a valid domain"]
  },
  "message": "One or more validation errors occurred."
}
</code></pre>
              </dd>
            </dl>
          </dd>
        </dl>

      </div>

      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="get-definition">
          <router-link :to="{ name: this.$route.name, hash: '#get-definition' }">Get Signal Definition</router-link>
        </h3>
        <p class="mb-2">Returns the signal definition (if available).</p>
        <div class="mb-4">
          <h4 class="font-semibold">Request Path</h4>
          <p>
            GET
            <code class="bg-gray-lightest">/signal/v1/signal-definition/{signalDefinitionId}</code>
          </p>
        </div>

        <div class="mb-4">
          <h4 class="mb-2 font-semibold">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>200</dp-table-cell>
                <dp-table-cell>Ok</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>400</dp-table-cell>
                <dp-table-cell>Bad Request</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>404</dp-table-cell>
                <dp-table-cell>Not Found</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>409</dp-table-cell>
                <dp-table-cell>Conflict</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">
            <div>Response Status: 200</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd>
                <dp-table>
                  <dp-table-header-row>
                    <dp-table-header-cell>Field</dp-table-header-cell>
                    <dp-table-header-cell>Type</dp-table-header-cell>
                    <dp-table-header-cell>Description</dp-table-header-cell>
                  </dp-table-header-row>
                  
                  <dp-table-body>
                    <dp-table-row>
                      <dp-table-cell>metadata</dp-table-cell>
                      <dp-table-cell>Metadata</dp-table-cell>
                      <dp-table-cell>See data type definition specified in POST signal-definition endpoint</dp-table-cell>
                    </dp-table-row>
                    <dp-table-row>
                      <dp-table-cell>topics</dp-table-cell>
                      <dp-table-cell>Object[](topic)</dp-table-cell>
                      <dp-table-cell>A collection of topics that inform the signal</dp-table-cell>
                    </dp-table-row>
                  </dp-table-body>                  
                </dp-table>
              </dd>
              <dt class="mb-2">Topic:</dt>
              <dd>
                <dp-table>
                  <dp-table-header-row>
                    <dp-table-header-cell>Field</dp-table-header-cell>
                    <dp-table-header-cell>Type</dp-table-header-cell>
                    <dp-table-header-cell>Description</dp-table-header-cell>
                  </dp-table-header-row>

                  <dp-table-body>
                    <dp-table-row>
                      <dp-table-cell>id</dp-table-cell>
                      <dp-table-cell>Integer</dp-table-cell>
                      <dp-table-cell>Topic identifier</dp-table-cell>
                    </dp-table-row>
                    <dp-table-row>
                      <dp-table-cell>name</dp-table-cell>
                      <dp-table-cell>String</dp-table-cell>
                      <dp-table-cell>Topic Name</dp-table-cell>
                    </dp-table-row>
                    <dp-table-row>
                      <dp-table-cell>weighting</dp-table-cell>
                      <dp-table-cell>Number</dp-table-cell>
                      <dp-table-cell>Weighting of the topic in the signal</dp-table-cell>
                    </dp-table-row>
                  </dp-table-body>
                </dp-table>
              </dd>
              <dt class="mb-2">
                Response Body Example
              </dt>
              <dd>
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
	"metadata": {
		"name": "Signal definition for X product",
		"description": "Definition created by Joe for the sales team."
	},
	"topics": [{
            "id": 12345,
			"name": "Wireless Communication",
			"weighting": 0.9
		},
		{
    		"id": 23456,
			"name": "Electric Vehicles (EV)",
			"weighting": 0.9
		}
	]
}
        </code></pre>
              </dd>
            </dl>
          </dd>

          <dt class="font-semibold">Request was not formatted properly</dt>
          <dd class="mb-4">Response Status: 400 Bad Request</dd>

          <dt class="font-semibold">Request’s token was either invalid or missing</dt>
          <dd class="mb-4">Response Status: 401 Unauthorized</dd>

          <dt class="font-semibold">Request’s token does not contain the required permissions</dt>
          <dd class="mb-4">Response Status: 403 Forbidden</dd>

          <dt class="font-semibold">Signal definition was not found</dt>
          <dd class="mb-4">Response Status: 404</dd>

          <dt class="font-semibold">Signal definition could not be returned due to a conflict with the resource’s state</dt>
          <dd class="mb-4">Response Status: 409</dd>
        </dl>

      </div>

      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="update-definition">
          <router-link :to="{ name: this.$route.name, hash: '#update-definition' }">Update Signal Definition</router-link>
        </h3>
        <p class="mb-2">Updates or replaces the signal definition.</p>
        <div class="mb-4">
          <h4 class="font-semibold">Request Path</h4>
          <p>
            PUT
            <code class="bg-gray-lightest">/signal/v1/signal-definition/{signalDefinitionId}</code>
          </p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold">Request Body</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>metadata</dp-table-cell>
                <dp-table-cell>Metadata</dp-table-cell>
                <dp-table-cell>Metadata associated with this signal definition</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>topics</dp-table-cell>
                <dp-table-cell>Object[](topic)</dp-table-cell>
                <dp-table-cell>(required) A collection of topics that inform the signal</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>

          <h3 class="font-semibold pt-3">Topic</h3>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>id</dp-table-cell>
                <dp-table-cell>Integer</dp-table-cell>
                <dp-table-cell>Topic identifier</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>weighting</dp-table-cell>
                <dp-table-cell>Number</dp-table-cell>
                <dp-table-cell>Weighting of the topic in the signal</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="mb-4 font-semibold">Request Body Example</h4>
          <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
	"metadata": {
		"name": "Signal definition for X product",
		"description": "Definition updated by Steve to improve signal."
	},
	"topics": [{
			"id": 12345,
			"weighting": 0.9
		},
		{
			"id": 20678,
			"weighting": 0.88
		}
	]
 }
        </code></pre>
        </div>

        <div class="mb-4">
          <h4 class="mb-2 font-semibold">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>200</dp-table-cell>
                <dp-table-cell>Ok</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>400</dp-table-cell>
                <dp-table-cell>Bad Request</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>404</dp-table-cell>
                <dp-table-cell>Not Found</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>409</dp-table-cell>
                <dp-table-cell>Conflict</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>422</dp-table-cell>
                <dp-table-cell>Unprocessable Entity</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">Response Status: 200</dd>

          <dt class="font-semibold">Request was not formatted properly</dt>
          <dd class="mb-4">Response Status: 400 Bad Request</dd>

          <dt class="font-semibold">Request’s token was either invalid or missing</dt>
          <dd class="mb-4">Response Status: 401 Unauthorized</dd>

          <dt class="font-semibold">Request’s token does not contain the required permissions</dt>
          <dd class="mb-4">Response Status: 403 Forbidden</dd>

          <dt class="font-semibold">Signal definition was not found</dt>
          <dd class="mb-4">Response Status: 404 Not Found</dd>

          <dt class="font-semibold">Signal definition could not be updated due to a conflict with the resource’s state</dt>
          <dd class="mb-4">Response Status: 409 Conflict</dd>

          <dt class="font-semibold">Request was invalid</dt>
          <dd class="mb-4">
            <div>Response Status: 422</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd class="text-sm bg-gray-lightest">
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "errors": {
    "topics": ["Topic id must be provided for each topic"]
  },
  "message": "One or more validation errors occurred."
}

</code></pre>
              </dd>
            </dl>
          </dd>
        </dl>

      </div>

      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="delete-definition">
          <router-link :to="{ name: this.$route.name, hash: '#delete-definition' }">Delete Signal Definition</router-link>
        </h3>
        <p class="mb-2">Deletes the signal definition.</p>
        <div class="mb-4">
          <h4 class="font-semibold">Request Path</h4>
          <p>
            DELETE
            <code class="bg-gray-lightest">/signal/v1/signal-definition/{signalDefinitionId}</code>
          </p>
        </div>

        <div class="mb-4">
          <h4 class="mb-2 font-semibold">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>200</dp-table-cell>
                <dp-table-cell>Ok</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>400</dp-table-cell>
                <dp-table-cell>Bad Request</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>404</dp-table-cell>
                <dp-table-cell>Not Found</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>409</dp-table-cell>
                <dp-table-cell>Conflict</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">Response Status: 200</dd>

          <dt class="font-semibold">Request was not formatted properly</dt>
          <dd class="mb-4">Response Status: 400 Bad Request</dd>

          <dt class="font-semibold">Request’s token was either invalid or missing</dt>
          <dd class="mb-4">Response Status: 401 Unauthorized</dd>

          <dt class="font-semibold">Request’s token does not contain the required permissions</dt>
          <dd class="mb-4">Response Status: 403 Forbidden</dd>

          <dt class="font-semibold">Signal definition was not found</dt>
          <dd class="mb-4">Response Status: 404 Not Found</dd>

          <dt class="font-semibold">Signal definition could not be updated due to a conflict with the resource’s state</dt>
          <dd class="mb-4">Response Status: 409 Conflict</dd>
          
        </dl>

      </div>

      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="get-definitions">
          <router-link :to="{ name: this.$route.name, hash: '#get-definitions' }">Get Signal Definitions</router-link>
        </h3>
        <p class="mb-2">Returns a list of the customer’s signal definitions.</p>
        <div class="mb-4">
          <h4 class="font-semibold">Request Path</h4>
          <p>
            GET
            <code class="bg-gray-lightest">/signal/v1/signal-definitions</code>
          </p>
        </div>

        <div class="mb-4">
          <h4 class="mb-2 font-semibold">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>200</dp-table-cell>
                <dp-table-cell>Ok</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>400</dp-table-cell>
                <dp-table-cell>Bad Request</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">
            <div>Response Status: 200</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd>
                <dp-table>
                  <dp-table-header-row>
                    <dp-table-header-cell>Field</dp-table-header-cell>
                    <dp-table-header-cell>Type</dp-table-header-cell>
                    <dp-table-header-cell>Description</dp-table-header-cell>
                  </dp-table-header-row>

                  <dp-table-body>
                    <dp-table-row>
                      <dp-table-cell>signalDefinitions</dp-table-cell>
                      <dp-table-cell>Object[](signalDefinition)</dp-table-cell>
                      <dp-table-cell>A collection of signal definitions</dp-table-cell>
                    </dp-table-row>
                  </dp-table-body>
                </dp-table>
              </dd>
              <dt class="mb-2">SignalDefinition:</dt>
              <dd>
                <dp-table>
                  <dp-table-header-row>
                    <dp-table-header-cell>Field</dp-table-header-cell>
                    <dp-table-header-cell>Type</dp-table-header-cell>
                    <dp-table-header-cell>Description</dp-table-header-cell>
                  </dp-table-header-row>

                  <dp-table-body>
                    <dp-table-row>
                      <dp-table-cell>signalDefinitionId</dp-table-cell>
                      <dp-table-cell>Uuid</dp-table-cell>
                      <dp-table-cell>Signal definition identifier</dp-table-cell>
                    </dp-table-row>
                    <dp-table-row>
                      <dp-table-cell>status</dp-table-cell>
                      <dp-table-cell>String</dp-table-cell>
                      <dp-table-cell>Processing state</dp-table-cell>
                    </dp-table-row>
                    <dp-table-row>
                      <dp-table-cell>metadata</dp-table-cell>
                      <dp-table-cell>Metadata</dp-table-cell>
                      <dp-table-cell>See data type definition specified in POST signal-definition endpoint </dp-table-cell>
                    </dp-table-row>
                  </dp-table-body>
                </dp-table>
              </dd>
              <dt class="mb-2">
                Response Body Example
              </dt>
              <dd>
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "signalDefinitions": [{
    "signalDefinitionId": "7030eb5a-311a-4608-b901-8e5d61aad86b",
    "status": "Processing",
    "metadata": {
		  "name": "Signal definition for X product",
		  "description": "Updated by Steve to improve signal."
	  }
  },
  {
    "signalDefinitionId": "e2350c4f-3f29-4779-8d6a-d1fc8bfbc95c",
    "status": "Available"
    "metadata": {
		  "name": "",
		  "description": ""
	  }
  }]
}

        </code></pre>
              </dd>
            </dl>
          </dd>

          <dt class="font-semibold">Request was not formatted properly</dt>
          <dd class="mb-4">Response Status: 400 Bad Request</dd>

          <dt class="font-semibold">Request’s token was either invalid or missing</dt>
          <dd class="mb-4">Response Status: 401 Unauthorized</dd>

          <dt class="font-semibold">Request’s token does not contain the required permissions</dt>
          <dd class="mb-4">Response Status: 403 Forbidden</dd>

          <dt class="font-semibold">Signal definition was not found</dt>
          <dd class="mb-4">Response Status: 404</dd>

          <dt class="font-semibold">Signal definition could not be returned due to a conflict with the resource’s state</dt>
          <dd class="mb-4">Response Status: 409</dd>
        </dl>

      </div>
      
    </div>

  </div>
</template>

<script>
import CollapsibleSection from "@/components/ux/CollapsibleSection";
import {scrollToAnchor} from "@/utils/document";
import {Table, TableBody, TableCell, TableHeaderCell, TableHeaderRow, TableRow} from "@/components/ux/Table";

export default {
  name: "SignalApiDocumentation",
  components: {
    CollapsibleSection,
    "dp-table": Table,
    "dp-table-body": TableBody,
    "dp-table-header-row": TableHeaderRow,
    "dp-table-row": TableRow,
    "dp-table-header-cell": TableHeaderCell,
    "dp-table-cell": TableCell,
  },
  watch: {
    $route(to, _from) {
      if (to.hash) {
        scrollToAnchor(to.hash)
      }
    },
  }
}
</script>

<style scoped>

</style>