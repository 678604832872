<template>
  <div id="content" class="flex-1 pl-6 min-w-0">

    <h1 class="mt-6 mb-6 text-2xl font-semibold">Bombora API Docs</h1>
    <h2 class="font-bold text-xl mb-4" id="authorization">
      <router-link :to="{ name: this.$route.name, hash: '#authorization' }">Authorization</router-link>
    </h2>
    <div class="mb-8">
      <p class="mb-4">
        The Bombora API uses the Client Credentials Flow. Using the client ID
        and client secret, a bearer token is retrieved. This token can be used
        in the authorization header of all Bombora API requests.
      </p>
  
      <h3 class="font-semibold text-lg">Retrieving a bearer token</h3>
      <div class="mb-6">
        <p class="mb-6">
          A bearer token can be requested by making a POST request using the
          client ID and client secret.
        </p>
        <div class="mb-2">
          POST
          <code class="bg-gray-lightest">https://api.bombora.com/oauth/token</code>
        </div>
  
        <dp-table>
          <dp-table-header-row>
            <dp-table-header-cell>Field</dp-table-header-cell>
            <dp-table-header-cell>Description</dp-table-header-cell>
          </dp-table-header-row>
          <dp-table-body>
            <dp-table-row>
              <dp-table-cell>client_id</dp-table-cell>
              <dp-table-cell>Client ID</dp-table-cell>
            </dp-table-row>
            <dp-table-row>
              <dp-table-cell>client_secret</dp-table-cell>
              <dp-table-cell>Client Secret</dp-table-cell>
            </dp-table-row>
          </dp-table-body>
        </dp-table>
      </div>
  
      <h4 class="mb-4 font-semibold">Example request</h4>
      <div class="mb-6">
            <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>curl --request POST \
       --url https://api.bombora.com/oauth/token \
       --header 'content-type: application/json' \
       --data '{"client_id":"myclientid","client_secret":"myclientsecret"}'</code></pre>
      </div>
  
      <h4 class="mb-4 font-semibold">Example response</h4>
      <div class="mb-6">
            <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
    "access_token": "mybearertoken",
    "scope":"create:dab update:dab",
    "expires_in": 86400,
    "token_type": "Bearer"
  }</code></pre>
      </div>
  
      <h3 class="font-semibold text-lg">Authorization Header</h3>
      <p class="mb-4">
        Using the token retrieved from the previous step, the authorization
        header is required for all Bombora API calls.
      </p>
  
      <h4 class="font-semibold">Example auth header</h4>
      <p>
        <code class="bg-gray-lightest">Authorization: Bearer mybearertoken</code>
      </p>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableBody,
  TableHeaderRow,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "../../ux/Table"
export default {
  name: "BomboraApi",
  components: {
    "dp-table": Table,
    "dp-table-body": TableBody,
    "dp-table-header-row": TableHeaderRow,
    "dp-table-row": TableRow,
    "dp-table-header-cell": TableHeaderCell,
    "dp-table-cell": TableCell,
  }
}
</script>