<template>
  <tr class="border-b border-gray-lighter">
    <slot />
  </tr>
</template>

<script>
export default {
  name: "TableRow"
}
</script>

<style></style>