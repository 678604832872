<template>
  <header class="main-content flex items-center text-blue-dark px-4">
    <router-link to="/" class="flex items-center">
      <img :src="brandIcon" alt="Bombora" style="height:34px;" /><span
        class="ml-2 text-lg font-bold"
        >Developer</span
      >
    </router-link>

    <nav class="flex-1 flex items-center justify-end text-sm">
      <div class="mr-10 lg:mr-20">
        <dropdown-menu
          id="docs-menu"
          :expanded="docsMenuExpanded"
          @toggle="toggleDocsMenu()"
          button-class="p-4"
        >
          <template v-slot:toggle>
            <div class="flex items-center">
              <span class="mr-1">App Docs</span>
              <chevron-down v-if="!docsMenuExpanded" svg-class="h-4 w-4 mt-1" />
              <chevron-up v-if="docsMenuExpanded" svg-class="h-4 w-4 mt-1" />
            </div>
          </template>
          <dropdown-menu-item>
            <router-link to="/docs/bombora">Bombora API Docs</router-link>
          </dropdown-menu-item>
          <dropdown-menu-item>
            <router-link to="/docs/dab">DAB API (v1)</router-link>
          </dropdown-menu-item>
          <dropdown-menu-item v-if="surgeApiDocsReleased">
            <router-link to="/docs/signal">Signal API (v1)</router-link>
          </dropdown-menu-item>          
        </dropdown-menu>
      </div>

      <a
        href="https://bombora.com/customer-support-forms/"
        class="mr-10 lg:mr-20"
        >Support</a
      >

      <router-link
        v-if="$auth.isAuthenticated.value && !apiCredsUnauthorized"
        to="/appkeys"
        class="btn-blue-dark mr-6"
        ><span class="px-1">Your App Key</span></router-link
      >
    </nav>

    <div class="partial-border-l text-sm">
      <div v-if="!isAuthenticated">
        <button
          title="Login"
          tabindex="0"
          @click="login"
          class="cursor-pointer flex items-center p-4"
        >
          <profile-icon svg-class="w-6 h-6" />
          <span class="ml-3">Login</span>
        </button>
      </div>

      <div v-if="isAuthenticated">
        <dropdown-menu
          id="profile-menu"
          :expanded="profileMenuExpanded"
          @toggle="toggleProfileMenu()"
          button-class="p-4"
        >
          <template v-slot:toggle>
            <div class="flex items-center">
              <profile-icon svg-class="w-6 h-6 text-orange" />
              <span class="ml-3">{{
                (user && user.given_name) || "Hello"
              }}</span>
            </div>
          </template>
          <dropdown-menu-item>
            <button title="Logout" @click="logout">Logout</button>
          </dropdown-menu-item>
        </dropdown-menu>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import { DropdownMenu, DropdownMenuItem } from "./ux/DropdownMenu";
import ChevronDownSvg from "./svg/ChevronDownSvg.vue";
import ChevronUpSvg from "./svg/ChevronUpSvg.vue";
import ProfileSvg from "./svg/ProfileSvg.vue";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

export default {
  name: "Header",
  components: {
    "chevron-down": ChevronDownSvg,
    "chevron-up": ChevronUpSvg,
    "profile-icon": ProfileSvg,
    "dropdown-menu": DropdownMenu,
    "dropdown-menu-item": DropdownMenuItem,
  },
  computed: mapState(["apiCredsUnauthorized"]),
  data() {
    return {
      brandIcon: require(`@/assets/bombora_icon.png`),
      docsMenuExpanded: false,
      profileMenuExpanded: false,
      isAuthenticated: this.$auth.isAuthenticated.value,
      user: (this.$auth.user && this.$auth.user.value) || {},
      surgeApiDocsReleased: useLDFlag('dev-portal-signal-docs-release', false)
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    toggleDocsMenu() {
      this.docsMenuExpanded = !this.docsMenuExpanded
    },
    toggleProfileMenu() {
      this.profileMenuExpanded = !this.profileMenuExpanded
    },
  },
  watch: {
    // When the route changes, make sure to close all header dropdowns.
    $route(_to, _from) {
      this.docsMenuExpanded = false
      this.profileMenuExpanded = false
    },
  },
}
</script>
