<template>
  <thead>
    <tr class="bg-gray-lightest text-gray-900 uppercase text-sm leading-normal">
      <slot />
    </tr>
  </thead>
</template>

<script>
export default {
  name: "TableHeaderRow"
}
</script>

<style></style>