<template>
  <h1 class="mt-6 mb-4 text-2xl font-semibold">
    Page Not Found
  </h1>
  
  <router-link to="/" class="text-orange">Return to Home</router-link>
</template>

<script>
export default {
  name: "NotFound"
}
</script>
