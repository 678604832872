<template>
  <div class="flex">
    <aside
      class="h-screen sticky top-0 flex-shrink-0 w-64 pt-6 h-full border-r border-color-gray-light"
    >
      <h2 class="font-bold px-4 mb-3 text-lg">Documentation</h2>

      <collapsible-section
        id="dab-documentation-nav"
        label="DAB API (v1) Docs"
        button-class="w-full font-bold border-t border-b border-l border-color-gray-light py-5 px-4 text-sm"
        :expanded="true"
      >
        <ul
          aria-labelledby="dab-documentation-nav"
          class="bg-gray-lightest p-4 text-blue-dark text-sm"
        >
          <li class="pt-3">
            <router-link
              :to="{ name: this.$route.name, hash: '#endpoints' }"
              class="font-semibold"
            >Endpoints</router-link>
            <ul class="px-6">
              <li class="pt-3">
                <router-link :to="{ name: this.$route.name, hash: '#activate' }">Activate</router-link>
              </li>
              <li class="pt-3">
                <router-link :to="{ name: this.$route.name, hash: '#create' }">Create</router-link>
              </li>
              <li class="pt-2">
                <router-link :to="{ name: this.$route.name, hash: '#edit' }">Edit</router-link>
              </li>
              <li class="pt-2">
                <router-link :to="{ name: this.$route.name, hash: '#estimate' }">Estimate</router-link>
              </li>
              <li class="pt-2">
                <router-link :to="{ name: this.$route.name, hash: '#get' }">Get</router-link>
              </li>
              <li class="pt-2">
                <router-link :to="{ name: this.$route.name, hash: '#delete' }">Delete</router-link>
              </li>
              <li class="pt-2">
                <router-link :to="{ name: this.$route.name, hash: '#suspend' }">Suspend</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </collapsible-section>
    </aside>

    <div id="content" class="flex-1 pl-6 min-w-0">
      <h1 class="mt-6 mb-6 text-2xl font-semibold">Digital Audience Builder (DAB) API</h1>

      <p class="mb-4">
        The Digital Audience Builder (DAB) API provides customers the ability to
        programmatically create and edit custom audiences.
      </p>

      <div class="mb-4 pb-4 border-b border-gray-lighter">
        <p class="text-sm">
          Jump to:
          <router-link
            :to="{ name: this.$route.name, hash: '#endpoints' }"
            class="text-orange"
          >Endpoints</router-link>
        </p>
      </div>

      <h2 class="font-bold text-xl mb-4" id="endpoints">
        <router-link :to="{ name: this.$route.name, hash: '#endpoints' }">Endpoints</router-link>
      </h2>
      <div class="mb-4 pb-2">
        <p class="text-sm">
          Jump to:
          <router-link
            :to="{ name: this.$route.name, hash: '#activate' }"
            class="text-orange"
          >Activate</router-link>|
          <router-link :to="{ name: this.$route.name, hash: '#create' }" class="text-orange">Create</router-link>|
          <router-link :to="{ name: this.$route.name, hash: '#edit' }" class="text-orange">Edit</router-link>|
          <router-link
            :to="{ name: this.$route.name, hash: '#estimate' }"
            class="text-orange"
          >Estimate</router-link>|
          <router-link :to="{ name: this.$route.name, hash: '#get' }" class="text-orange">Get</router-link>|
          <router-link :to="{ name: this.$route.name, hash: '#delete' }" class="text-orange">Delete</router-link>|
          <router-link
            :to="{ name: this.$route.name, hash: '#suspend' }"
            class="text-orange"
          >Suspend</router-link>
        </p>
      </div>

      <!-- ACTIVATE -->
      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="activate">
          <router-link :to="{ name: this.$route.name, hash: '#activate' }">ACTIVATE</router-link>
        </h3>
        <p class="mb-2">The Activate endpoint allows users to activate an existing suspended Digital Audience.</p>

        <p class="mb-4">Note: The Last Modified By column for the Digital Audience will be set as DAB API.</p>

        <div class="mb-4">
          <h4 class="font-semibold">Request Path</h4>
          <p>
            PUT
            <code class="bg-gray-lightest">/digital-audiences/v1/[dataexchange]/[externalId]/[partnerId]/activate</code>
          </p>
          <p>[partnerId] required for Adobe, Xandr (Appnexus), and theTradeDesk</p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Data Exchanges</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Data Exchange</dp-table-header-cell>
              <dp-table-header-cell>dataexchange path</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Adobe</dp-table-cell>
                <dp-table-cell>adobe</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Xandr (AppNexus)</dp-table-cell>
                <dp-table-cell>appnexus</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>BlueKai</dp-table-cell>
                <dp-table-cell>blue_kai</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Eyeota</dp-table-cell>
                <dp-table-cell>eyeota</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>LiveRamp</dp-table-cell>
                <dp-table-cell>liveramp</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Lotame</dp-table-cell>
                <dp-table-cell>lotame</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Test Only</dp-table-cell>
                <dp-table-cell>measure</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>MediaMathDirect</dp-table-cell>
                <dp-table-cell>media_math_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Taboola</dp-table-cell>
                <dp-table-cell>taboola_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>theTradeDesk</dp-table-cell>
                <dp-table-cell>trade_desk_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Visitor Insights w/ Real Time Capabilities</dp-table-cell>
                <dp-table-cell>rtvi</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-4">
          <h4 class="mb-2 font-semibold">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>204</dp-table-cell>
                <dp-table-cell>No Content</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>409</dp-table-cell>
                <dp-table-cell>Conflict</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>500</dp-table-cell>
                <dp-table-cell>Internal Server Error</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>502</dp-table-cell>
                <dp-table-cell>Bad Gateway</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">Response Status: 204</dd>

          <dt class="font-semibold">Request was not properly authorized</dt>
          <dd class="mb-4">Response Status: 401</dd>

          <dt class="font-semibold">Request for segment activation was not properly authorized</dt>
          <dd class="mb-4">Response Status: 403</dd>

          <dt class="font-semibold">Segment does not exist</dt>
          <dd class="mb-4">Response Status: 404</dd>

          <dt class="font-semibold">Segment does not have prerequired "Suspended" status</dt>
          <dd class="mb-4">Response Status: 409</dd>
        </dl>
      </div>

      <!-- CREATE -->
      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="create">
          <router-link :to="{ name: this.$route.name, hash: '#create' }">CREATE</router-link>
        </h3>
        <p class="mb-2">
          The Create endpoint allows customers to generate a new DAB custom
          audience.
        </p>
        <p class="mb-4">Note: The Last Modified By column for the Digital Audience will be set as DAB API.</p>

        <div class="mb-4">
          <h4 class="font-semibold">Request Path</h4>
          <p>
            POST
            <code class="bg-gray-lightest">/digital-audiences/v1/[dataexchange]</code>
          </p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Data Exchanges</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Data Exchange</dp-table-header-cell>
              <dp-table-header-cell>dataexchange path</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Adobe</dp-table-cell>
                <dp-table-cell>adobe</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Xandr (AppNexus)</dp-table-cell>
                <dp-table-cell>appnexus</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>BlueKai</dp-table-cell>
                <dp-table-cell>blue_kai</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Eyeota</dp-table-cell>
                <dp-table-cell>eyeota</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>LiveRamp</dp-table-cell>
                <dp-table-cell>liveramp</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Lotame</dp-table-cell>
                <dp-table-cell>lotame</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Test Only</dp-table-cell>
                <dp-table-cell>measure</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>MediaMathDirect</dp-table-cell>
                <dp-table-cell>media_math_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Taboola</dp-table-cell>
                <dp-table-cell>taboola_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>theTradeDesk</dp-table-cell>
                <dp-table-cell>trade_desk_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Visitor Insights w/ Real Time Capabilities</dp-table-cell>
                <dp-table-cell>rtvi</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold">Request Body</h4>
          <p class="mb-2">
            The request body includes the following data in a form-data
            structure:
          </p>

          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Required</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>externalId</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>required unless using Xandr (Appnexus)</dp-table-cell>
                <dp-table-cell>
                  Unique identifier to associate with this segment. Only
                  letters, numbers, dashes (-), and underscores (_) are
                  accepted. If MediaMathDirect, must be a numeric value with 7
                  or more digits.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>partnerId</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>
                  required for Adobe, Xandr (Appnexus), and
                  theTradeDesk
                </dp-table-cell>
                <dp-table-cell>
                  Partner or Member ID. Adobe and Xandr (AppNexus) partner IDs
                  must be numeric values. For theTradeDesk partner IDs, only
                  letters, numbers, dashes (-), and underscores (_) are
                  accepted.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>cpmPrice</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>
                  required for Xander (Appnexus) and
                  theTradeDesk
                </dp-table-cell>
                <dp-table-cell>
                  CPM associated with the segment. Must be in $0.25 increments
                  for Xandr.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>name</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>Name of custom audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>endDate</dp-table-cell>
                <dp-table-cell>datetime</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>
                  Date when the audience is suspended. Must be no more than 13
                  months in the future.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>topics</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  List of topic IDs of your audience&#39;s
                  interest
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>domains</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>List of domains of your audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>filters</dp-table-cell>
                <dp-table-cell>filter[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>List of filter objects to filter your audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>surgeId</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  ID of Company Surge &reg; Report to link (disregards supplied
                  domains if provided)
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>granularInstallData</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  List of granular install products for your
                  audience
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>manufacturingData</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  List of manufacturing categories of your
                  audience
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>filterOr</dp-table-cell>
                <dp-table-cell>bool</dp-table-cell>
                <dp-table-cell>optional</dp-table-cell>
                <dp-table-cell>
                  Combine filter conditions with OR (&quot;Show me user
                  profiles in finance or located in Japan&quot;). Defaults to
                  false.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>entityParty</dp-table-cell>
                <dp-table-cell>int</dp-table-cell>
                <dp-table-cell>optional</dp-table-cell>
                <dp-table-cell>
                  Options are &quot;1&quot; (First) or &quot;3&quot; (Third).
                  Select First Party to only include your First Party Audience
                  in the segment. Defaults to &quot;3&quot;.
                </dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
          <p>* requires at least one of these fields</p>
        </div>

        <div class="mb-6">
          <h5 class="mb-2 font-semibold">Filter</h5>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Required</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>id</dp-table-cell>
                <dp-table-cell>string</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>Name of filter category</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>values</dp-table-cell>
                <dp-table-cell>string[]</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>List of values to target in given filter</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
          <div class="text-sm mt-2">
            <router-link to="/docs/dab/filter" class="text-orange">Click here</router-link> for a full list of ids and values available for filtering.
          </div>
        </div>

        <div class="mb-6">
          <h4 class="mb-4 font-semibold">Request Body Example</h4>
          <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>curl --location --request POST 'https://api.bombora.com/digital-audiences/v1/adobe' \
     --header 'Content-Type: application/json' \
     --data-raw '{
       "partnerId":"123",
       "externalId": "testaudience123",
       "name": "My Test Audience",
       "endDate": "2021-12-23T19:53:35.984Z",
       "topics": [ "1503561", "1503565" ],
       "filterOr": false,
       "domains": [ "bombora.com", "spotify.com" ],
       "entityParty": "3",
       "filters": [
         { "id": "ccm_industry", "values": [ "Software" ] },
         { "id": "functional_area", "values": [ "Marketing" ] }
       ]
     }'</code></pre>
        </div>

        <div class="mb-4">
          <h4 class="mb-2 font-semibold">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>201</dp-table-cell>
                <dp-table-cell>Created</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>409</dp-table-cell>
                <dp-table-cell>Conflict</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>422</dp-table-cell>
                <dp-table-cell>Unprocessable Entity</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>500</dp-table-cell>
                <dp-table-cell>Internal Server Error</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>502</dp-table-cell>
                <dp-table-cell>Bad Gateway</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">Response Status: 201 Created</dd>

          <dt class="font-semibold">Request had conflict</dt>
          <dd class="mb-4">
            <div>Response Status: 409 Conflict</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd class="text-sm">
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{ "message": "Given External Id is already in use. Please retry with a new External Id." }</code></pre>
              </dd>
            </dl>
          </dd>

          <dt class="font-semibold">Request was invalid</dt>
          <dd class="mb-4">
            <div>Response Status: 422</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd class="text-sm">
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "errors": {
    "cpmPrice": [ "CPM price 0.25 provided is not supported for eyeota." ],
    "externalId": [ "External id containing only numbers, letters, - or _ expected for creating eyeota digital audience." ]
  },
  "message": "One or more validation errors occurred."
}</code></pre>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>

      <!-- EDIT -->
      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="edit">
          <router-link :to="{ name: this.$route.name, hash: '#edit' }">EDIT</router-link>
        </h3>
        <p class="mb-2">
          The Edit endpoint allows customers to modify an existing DAB custom
          audience. This endpoint is a full overwrite of the existing custom
          audience settings.
        </p>
        <p class="mb-4">Note: The Last Modified By column for the Digital Audience will be set as DAB API.</p>

        <div class="mb-6">
          <h4 class="font-semibold">Request Path</h4>
          <div>
            PUT
            <code class="bg-gray-lightest">/digital-audiences/v1/[dataexchange]/[externalId]/[partnerId]</code>
          </div>
          <p>[partnerId] required for Adobe, Xandr (Appnexus), and theTradeDesk</p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Data Exchanges</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Data Exchange</dp-table-header-cell>
              <dp-table-header-cell>dataexchange path</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Adobe</dp-table-cell>
                <dp-table-cell>adobe</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Xandr (AppNexus)</dp-table-cell>
                <dp-table-cell>appnexus</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>BlueKai</dp-table-cell>
                <dp-table-cell>blue_kai</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Eyeota</dp-table-cell>
                <dp-table-cell>eyeota</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>LiveRamp</dp-table-cell>
                <dp-table-cell>liveramp</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Lotame</dp-table-cell>
                <dp-table-cell>lotame</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Test Only</dp-table-cell>
                <dp-table-cell>measure</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>MediaMathDirect</dp-table-cell>
                <dp-table-cell>media_math_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Taboola</dp-table-cell>
                <dp-table-cell>taboola_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>theTradeDesk</dp-table-cell>
                <dp-table-cell>trade_desk_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Visitor Insights w/ Real Time Capabilities</dp-table-cell>
                <dp-table-cell>rtvi</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Request Body</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Required</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>cpmPrice</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>
                  required for Xander (Appnexus) and
                  theTradeDesk
                </dp-table-cell>
                <dp-table-cell>
                  CPM associated with the segment. Must be in $0.25 increments
                  for Xandr.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>name</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>Name of Digital Audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>endDate</dp-table-cell>
                <dp-table-cell>datetime</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>
                  Date to deactivate segment. Must be no more than 13 months in
                  the future.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>topics</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  List of topic IDs of your audience&#39;s
                  interest
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>domains</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>List of domains of your audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>filters</dp-table-cell>
                <dp-table-cell>filter[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>List of filter objects to filter your audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>surgeId</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  ID of Company Surge &reg; Report to link (disregards supplied
                  domains if provided)
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>granularInstallData</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>Granular Install products to target</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>manufacturingData</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>Manufacturing to target</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>filterOr</dp-table-cell>
                <dp-table-cell>bool</dp-table-cell>
                <dp-table-cell>optional</dp-table-cell>
                <dp-table-cell>
                  Combine filter conditions with OR (&quot;Show me user
                  profiles in finance or located in Japan&quot;). Defaults to
                  false.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>entityParty</dp-table-cell>
                <dp-table-cell>int</dp-table-cell>
                <dp-table-cell>optional</dp-table-cell>
                <dp-table-cell>
                  Options are &quot;1&quot; (First) or &quot;3&quot; (Third).
                  Select First Party to only include your First Party Audience
                  in the segment. Defaults to &quot;3&quot;.
                </dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
          <p>* requires at least one of these fields</p>
        </div>

        <div class="mb-6">
          <h5 class="mb-2 font-semibold">Filter</h5>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Required</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>id</dp-table-cell>
                <dp-table-cell>string</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>name of filter category</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>values</dp-table-cell>
                <dp-table-cell>string[]</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>List of values to target in given filter</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
          <div class="text-sm mt-2">
            <router-link to="/docs/dab/filter" class="text-orange">Click here</router-link> for a full list of ids and values available for filtering.
          </div>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Request Body Example</h4>
          <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>curl --location --request PUT 'https://api.bombora.com/digital-audiences/v1/adobe/testaudience123/123' \
     --header 'Content-Type: application/json' \
     --data-raw '{
        "name": "My Test Audience",
        "endDate": "2021-12-27T19:53:35.984Z",
        "topics": [ "1503561" ],
        "filterOr": false,
        "domains": [ "bombora.com", "spotify.com" ],
        "entityParty": "3",
        "filters": [
          { "id": "ccm_industry", "values": [ "Software" ] },
          { "id": "functional_area", "values": [ "Marketing" ] }
        ]
      }'</code></pre>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>

            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>204</dp-table-cell>
                <dp-table-cell>No Content</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>404</dp-table-cell>
                <dp-table-cell>Not Found</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>422</dp-table-cell>
                <dp-table-cell>Unprocessable Entity</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>500</dp-table-cell>
                <dp-table-cell>Internal Server Error</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>502</dp-table-cell>
                <dp-table-cell>Bad Gateway</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">Response Status: 204</dd>

          <dt class="font-semibold">Request was not found</dt>
          <dd class="mb-4">Response Status: 404</dd>

          <dt class="font-semibold">Request was invalid</dt>
          <dd>
            <div>Response Status: 422</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd>
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "errors": {
    "filters": [ "Filters are expected to have both an id and a list of values." ]
  },
  "message": "One or more validation errors occurred."
}</code></pre>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>

      <!-- ESTIMATOR -->
      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="estimate">
          <router-link :to="{ name: this.$route.name, hash: '#estimate' }">ESTIMATE</router-link>
        </h3>
        <p class="mb-2">
          The Estimate endpoint allows customers to estimate the size of an
          audience.
        </p>

        <div class="mb-6">
          <h4 class="font-semibold">Request Path</h4>
          <div>
            POST
            <code class="bg-gray-lightest">/digital-audiences/v1/[dataexchange]/estimate</code>
          </div>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Data Exchanges</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Data Exchange</dp-table-header-cell>
              <dp-table-header-cell>dataexchange path</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Adobe</dp-table-cell>
                <dp-table-cell>adobe</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Xandr (AppNexus)</dp-table-cell>
                <dp-table-cell>appnexus</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>BlueKai</dp-table-cell>
                <dp-table-cell>blue_kai</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Eyeota</dp-table-cell>
                <dp-table-cell>eyeota</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>LiveRamp</dp-table-cell>
                <dp-table-cell>liveramp</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Lotame</dp-table-cell>
                <dp-table-cell>lotame</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Test Only</dp-table-cell>
                <dp-table-cell>measure</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>MediaMathDirect</dp-table-cell>
                <dp-table-cell>media_math_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Taboola</dp-table-cell>
                <dp-table-cell>taboola_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>theTradeDesk</dp-table-cell>
                <dp-table-cell>trade_desk_direct</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold">Request Body</h4>
          <p class="mb-2">
            The request body includes the following data in a form-data
            structure:
          </p>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Required</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>topics</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  List of topic IDs of your audience&#39;s
                  interest
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>domains</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>List of domains of your audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>filters</dp-table-cell>
                <dp-table-cell>filter[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>List of filter objects to filter your audience</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>surgeId</dp-table-cell>
                <dp-table-cell>str</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>
                  ID of Company Surge &reg; Report to link (disregards supplied
                  domains if provided)
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>granularInstallData</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>Granular Install products to target</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>manufacturingData</dp-table-cell>
                <dp-table-cell>str[]</dp-table-cell>
                <dp-table-cell>optional*</dp-table-cell>
                <dp-table-cell>Manufacturing to target</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>filterOr</dp-table-cell>
                <dp-table-cell>bool</dp-table-cell>
                <dp-table-cell>optional</dp-table-cell>
                <dp-table-cell>
                  Combine filter conditions with OR (&quot;Show me user
                  profiles in finance or located in Japan&quot;). Defaults to
                  false.
                </dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>entityParty</dp-table-cell>
                <dp-table-cell>int</dp-table-cell>
                <dp-table-cell>optional</dp-table-cell>
                <dp-table-cell>
                  Options are &quot;1&quot; (First) or &quot;3&quot; (Third).
                  Select First Party to only include your First Party Audience
                  in the segment. Defaults to &quot;3&quot;.
                </dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
          <p>* requires either domains, topics or filters</p>
        </div>

        <div class="mb-6">
          <h5 class="mb-2 font-semibold">Filter</h5>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Field</dp-table-header-cell>
              <dp-table-header-cell>Type</dp-table-header-cell>
              <dp-table-header-cell>Required</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>id</dp-table-cell>
                <dp-table-cell>string</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>name of filter category</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>values</dp-table-cell>
                <dp-table-cell>string[]</dp-table-cell>
                <dp-table-cell>required</dp-table-cell>
                <dp-table-cell>List of values to target in given filter</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
          <div class="text-sm mt-2">
            <router-link to="/docs/dab/filter" class="text-orange">Click here</router-link> for a full list of ids and values available for filtering.
          </div>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Request Body Example</h4>
          <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>curl --location --request POST 'https://api.bombora.com/digital-audiences/v1/adobe/estimate' \
     --header 'Content-Type: application/json' \
     --data-raw '{
       "topics": [ "1503561", "1503565" ],
       "filterOr": false,
       "domains": [ "www.bombora.com", "www.spotify.com" ],
       "entityParty": "3",
       "filters": [
         { "id": "ccm_industry", "values": [ "Agriculture" ] },
         { "id": "functional_area", "values": [ "Building & Grounds Maintenance" ] }
       ]
     }'</code></pre>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>200</dp-table-cell>
                <dp-table-cell>OK</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>422</dp-table-cell>
                <dp-table-cell>Unprocessable Entity</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>502</dp-table-cell>
                <dp-table-cell>Bad Gateway</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">
            <div>Response Status: 200 OK</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd>
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "count": 3904
}</code></pre>
              </dd>
            </dl>
          </dd>
          <dt class="font-semibold">Request was invalid</dt>
          <dd>
            <div>Response Status: 422</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd class="text-sm bg-gray-lightest">
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
  "errors": {
    "granularInstallData": [ "Invalid ids: 23" ]
  },
  "message": "One or more validation errors occurred."
}</code></pre>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>

      <!-- GET -->
      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="get">
          <router-link :to="{ name: this.$route.name, hash: '#get' }">GET</router-link>
        </h3>
        <p class="mb-2">The Get endpoint allows users to retrieve an existing Digital Audience.</p>

        <div class="mb-6">
          <h4 class="font-semibold">Request Path</h4>
          <div>
            GET
            <code
              class="bg-gray-lightest"
            >/digital-audiences/v1/[dataexchange]/[externalId]/[partnerId]</code>
          </div>
          <p class="text-sm">[partnerId] required for Adobe, Xandr (Appnexus), and theTradeDesk</p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Data Exchanges</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Data Exchange</dp-table-header-cell>
              <dp-table-header-cell>dataexchange path</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Adobe</dp-table-cell>
                <dp-table-cell>adobe</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Xandr (AppNexus)</dp-table-cell>
                <dp-table-cell>appnexus</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>BlueKai</dp-table-cell>
                <dp-table-cell>blue_kai</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Eyeota</dp-table-cell>
                <dp-table-cell>eyeota</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>LiveRamp</dp-table-cell>
                <dp-table-cell>liveramp</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Lotame</dp-table-cell>
                <dp-table-cell>lotame</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Test Only</dp-table-cell>
                <dp-table-cell>measure</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>MediaMathDirect</dp-table-cell>
                <dp-table-cell>media_math_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Taboola</dp-table-cell>
                <dp-table-cell>taboola_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>theTradeDesk</dp-table-cell>
                <dp-table-cell>trade_desk_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Visitor Insights w/ Real Time Capabilities</dp-table-cell>
                <dp-table-cell>rtvi</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>200</dp-table-cell>
                <dp-table-cell>OK</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>500</dp-table-cell>
                <dp-table-cell>Internal Server Error</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>502</dp-table-cell>
                <dp-table-cell>Bad Gateway</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">
            <div>Response Status: 200</div>
            <dl>
              <dt class="mb-2">Response Body:</dt>
              <dd class="text-sm bg-gray-lightest">
                <pre class="bg-gray-lightest text-sm whitespace-pre-wrap"><code>{
    <span class="hljs-attr">"externalId"</span>: <span class="hljs-string">"audience123"</span>,
    <span class="hljs-attr">"partnerId"</span>: <span class="hljs-string">"123"</span>,
    <span class="hljs-attr">"name"</span>: <span class="hljs-string">"Example Audience"</span>,
    <span class="hljs-attr">"endDate"</span>: <span class="hljs-string">"2021-12-23T19:53:35.984Z"</span>,
    <span class="hljs-attr">"filterOr"</span>: <span class="hljs-literal">false</span>,
    <span class="hljs-attr">"topics"</span>: [<span class="hljs-string">"topic1"</span>, <span class="hljs-string">"topic2"</span>],
    <span class="hljs-attr">"domains"</span>: [<span class="hljs-string">"url1.com"</span>, <span class="hljs-string">"url2.com"</span>],
    <span class="hljs-attr">"entityParty"</span>: <span class="hljs-string">"3"</span>,
    <span class="hljs-attr">"surgeId"</span>: <span class="hljs-string">"123"</span>,
    <span class="hljs-attr">"cpmPrice"</span>: <span class="hljs-string">"1.00"</span>,
    <span class="hljs-attr">"filters"</span>: [
        {<span class="hljs-attr">"id"</span>: <span class="hljs-string">"filter1"</span>, <span class="hljs-attr">"values"</span>: [<span class="hljs-string">"value1"</span>]},
        {<span class="hljs-attr">"id"</span>: <span class="hljs-string">"filter2"</span>, <span class="hljs-attr">"values"</span>: [<span class="hljs-string">"value2"</span>]}
    ],
    <span class="hljs-attr">"granularInstallData"</span>: [<span class="hljs-string">"10000"</span>, <span class="hljs-string">"10001"</span>, <span class="hljs-string">"10002"</span>],
    <span class="hljs-attr">"manufacturingData"</span>: [<span class="hljs-string">"200000"</span>, <span class="hljs-string">"200001"</span>, <span class="hljs-string">"200002"</span>],
    <span class="hljs-attr">"status"</span>: <span class="hljs-string">"ACTIVE"</span>
}</code></pre>
              </dd>
            </dl>
          </dd>

          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Status</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Pending</dp-table-cell>
                <dp-table-cell>Accepted and awaiting first processing</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Active</dp-table-cell>
                <dp-table-cell>Included in daily processing</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Suspended</dp-table-cell>
                <dp-table-cell>Excluded from daily processing</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>

          <dt class="font-semibold">Request was not properly authorized</dt>
          <dd class="mb-4">Response Status: 401</dd>

          <dt class="font-semibold">Request was not found</dt>
          <dd class="mb-4">Response Status: 404</dd>
        </dl>
      </div>

      <!-- DELETE -->
      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="delete">
          <router-link :to="{ name: this.$route.name, hash: '#delete' }">DELETE</router-link>
        </h3>
        <p class="mb-2">
          The Delete endpoint allows users to delete an existing Digital
          Audience.
        </p>

        <div class="mb-6">
          <h4 class="font-semibold">Request Path</h4>
          <div>
            DELETE
            <code
              class="bg-gray-lightest"
            >/digital-audiences/v1/[dataexchange]/[externalId]/[partnerId]</code>
          </div>
          <p class="text-sm">[partnerId] required for Adobe, Xandr (Appnexus), and theTradeDesk</p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Data Exchanges</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Data Exchange</dp-table-header-cell>
              <dp-table-header-cell>dataexchange path</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Adobe</dp-table-cell>
                <dp-table-cell>adobe</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Xandr (AppNexus)</dp-table-cell>
                <dp-table-cell>appnexus</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>BlueKai</dp-table-cell>
                <dp-table-cell>blue_kai</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Eyeota</dp-table-cell>
                <dp-table-cell>eyeota</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>LiveRamp</dp-table-cell>
                <dp-table-cell>liveramp</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Lotame</dp-table-cell>
                <dp-table-cell>lotame</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Test Only</dp-table-cell>
                <dp-table-cell>measure</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>MediaMathDirect</dp-table-cell>
                <dp-table-cell>media_math_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Taboola</dp-table-cell>
                <dp-table-cell>taboola_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>theTradeDesk</dp-table-cell>
                <dp-table-cell>trade_desk_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Visitor Insights w/ Real Time Capabilities</dp-table-cell>
                <dp-table-cell>rtvi</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>204</dp-table-cell>
                <dp-table-cell>No Content</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>500</dp-table-cell>
                <dp-table-cell>Internal Server Error</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>502</dp-table-cell>
                <dp-table-cell>Bad Gateway</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">Response Status: 204</dd>

          <dt class="font-semibold">Request was not properly authorized</dt>
          <dd class="mb-4">Response Status: 401</dd>
        </dl>
      </div>

      <!-- SUSPEND -->
      <div class="mb-8">
        <h3 class="font-semibold text-lg" id="suspend">
          <router-link :to="{ name: this.$route.name, hash: '#suspend' }">SUSPEND</router-link>
        </h3>
        <p class="mb-2">
          The Suspend endpoint allows users to suspend an existing Digital
          Audience.
        </p>
        <p class="mb-4">Note: The Last Modified By column for the Digital Audience will be set as DAB API.</p>

        <div class="mb-6">
          <h4 class="font-semibold">Request Path</h4>
          <div>
            PUT
            <code class="bg-gray-lightest">/digital-audiences/v1/[dataexchange]/[externalId]/[partnerId]/suspend</code>
          </div>
          <p class="text-sm">[partnerId] required for Adobe, Xandr (Appnexus), and theTradeDesk</p>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Data Exchanges</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Data Exchange</dp-table-header-cell>
              <dp-table-header-cell>dataexchange path</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>Adobe</dp-table-cell>
                <dp-table-cell>adobe</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Xandr (AppNexus)</dp-table-cell>
                <dp-table-cell>appnexus</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>BlueKai</dp-table-cell>
                <dp-table-cell>blue_kai</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Eyeota</dp-table-cell>
                <dp-table-cell>eyeota</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>LiveRamp</dp-table-cell>
                <dp-table-cell>liveramp</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Lotame</dp-table-cell>
                <dp-table-cell>lotame</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Test Only</dp-table-cell>
                <dp-table-cell>measure</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>MediaMathDirect</dp-table-cell>
                <dp-table-cell>media_math_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Taboola</dp-table-cell>
                <dp-table-cell>taboola_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>theTradeDesk</dp-table-cell>
                <dp-table-cell>trade_desk_direct</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>Visitor Insights w/ Real Time Capabilities</dp-table-cell>
                <dp-table-cell>rtvi</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <div class="mb-6">
          <h4 class="font-semibold mb-2">Response</h4>
          <dp-table>
            <dp-table-header-row>
              <dp-table-header-cell>Response Code</dp-table-header-cell>
              <dp-table-header-cell>Description</dp-table-header-cell>
            </dp-table-header-row>
            <dp-table-body>
              <dp-table-row>
                <dp-table-cell>204</dp-table-cell>
                <dp-table-cell>No Content</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>401</dp-table-cell>
                <dp-table-cell>Unauthorized</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>403</dp-table-cell>
                <dp-table-cell>Forbidden</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>404</dp-table-cell>
                <dp-table-cell>Not Found</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>500</dp-table-cell>
                <dp-table-cell>Internal Server Error</dp-table-cell>
              </dp-table-row>
              <dp-table-row>
                <dp-table-cell>502</dp-table-cell>
                <dp-table-cell>Bad Gateway</dp-table-cell>
              </dp-table-row>
            </dp-table-body>
          </dp-table>
        </div>

        <dl>
          <dt class="font-semibold">Request was successful</dt>
          <dd class="mb-4">Response Status: 204</dd>

          <dt class="font-semibold">Request was not properly authorized</dt>
          <dd class="mb-4">Response Status: 401</dd>

          <dt class="font-semibold">Request for segment suspension was not properly authorized</dt>
          <dd class="mb-4">Response Status: 403</dd>

          <dt class="font-semibold">Segment does not exist</dt>
          <dd class="mb-4">Response Status: 404</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import {
  Table,
  TableBody,
  TableHeaderRow,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "../../ux/Table"
import CollapsibleSection from "@/components/ux/CollapsibleSection"
import { scrollToAnchor } from "@/utils/document"

export default {
  name: "DabApiDocumentation",
  components: {
    CollapsibleSection,
    "dp-table": Table,
    "dp-table-body": TableBody,
    "dp-table-header-row": TableHeaderRow,
    "dp-table-row": TableRow,
    "dp-table-header-cell": TableHeaderCell,
    "dp-table-cell": TableCell,
  },
  computed: mapState([]),
  data: function() {
    return {}
  },
  watch: {
    $route(to, _from) {
      if (to.hash) {
        scrollToAnchor(to.hash)
      }
    },
  },
  methods: {},
}
</script>
