function copyStringToClipboard(str) {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
}

/*
 * Given an anchor hash string (ex: #element), scrolls to that element.
 * It explicitly sets x to 0 in window.scrollTo to ensure the window doesn't jump
 * to the side when an element isn't left-aligned.
 */
function scrollToAnchor(hash) {
    const id = hash.slice(1); // remove initial '#'
    const target = document.querySelector(`[id='${id}']`);
    window.scrollTo(0, target.offsetTop);
}

export {
    copyStringToClipboard,
    scrollToAnchor
}