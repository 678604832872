<template>
  <div class="px-4">
    <div class="flex mt-6">
      <div class="flex-1 mb-6 mr-10">
        <h1 class="mt-6 mb-4 text-2xl font-bold tracking-normal text-blue-dark">
          Powered by Intent
        </h1>

        <p class="font-semibold">Welcome to the Bombora Developer Portal</p>
        <p class="mb-6">
          Integrate your apps with Bombora’s Company Surge® data trusted by
          respected partners and brands.
        </p>

        <div v-if="!$auth.loading.value">
          <!-- user is not logged in -->
          <div v-if="!$auth.isAuthenticated.value">
            <button-orange @click="login"
              ><span class="px-6">Login to Get Started</span></button-orange
            >
          </div>

          <!-- user is logged in -->
          <div v-if="$auth.isAuthenticated.value">
            <!-- user is not authorized to access app key -->
            <div v-if="apiCredsUnauthorized">
              <p class="mb-8">
                Your Account currently does not have App Key access. Please
                contact your customer success representative or
                <a href="https://bombora.com/contact-us/" class="text-orange"
                  >contact us</a
                >
                to receive access.
              </p>

              <a href="https://bombora.com/contact-us/" class="btn-orange"
                ><span class="px-12">Contact Us</span></a
              >
            </div>

            <!-- user is authorized to access app key -->
            <div v-if="!apiCredsUnauthorized">
              <p class="mb-8">
                Get started with Bombora's DAB API by creating your DAB API app
                key.
              </p>

              <router-link to="/appkeys" class="btn-orange"
                ><span class="px-2">Access Your App Keys</span></router-link
              >
            </div>

            <p class="mt-8 pt-10 border-t border-gray-200">
              <router-link to="/docs/dab" class="text-orange"
                >Read the DAB API Documentation</router-link
              >
              to learn more about creating and updating digital audience
              segments via our API.
            </p>
          </div>
        </div>
      </div>

      <img
        :src="gearPng"
        alt="Gears"
        class="block flex-0"
        style="height: 356px;"
      />
    </div>
  </div>
</template>

<script>
import ButtonOrange from "../../ux/ButtonOrange.vue"
import { mapActions, mapState } from "vuex"

export default {
  name: "HomePage",
  components: {
    ButtonOrange,
  },
  computed: mapState(["apiCredsUnauthorized"]),
  data: function() {
    return {
      showSecret: false,
      idIsCopied: false,
      secretIsCopied: false,
      gearPng: require("@/assets/gear.png"),
    }
  },
  mounted() {
    if (this.$auth.isAuthenticated.value) {
      this.getApiCredentials()
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    ...mapActions(["getApiCredentials"]),
  },
}
</script>
