<template>
  <button
      @click="click"
      class="btn-orange"
  >
    <slot></slot>
  </button>
</template>

<style></style>
<script>
export default {
  name: "NavButton",
  props: {
    click: {
      type: Function,
    }
  },
}
</script>
