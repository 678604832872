<template>
  <tbody class="text-gray-900 text-sm font-light">
    <slot />
  </tbody>
</template>

<script>
export default {
  name: "TableBody"
}
</script>

<style></style>