<template>
  <button @click="toggleExpanded()" :aria-expanded="isExpanded" :class="`flex items-center ${buttonClass}`">
    <span :id="id" class="flex-1 text-left">{{label}}</span>
    <span class="text-gray-light">
      <chevron-down-svg v-if="!isExpanded" />
      <chevron-up-svg v-if="isExpanded" />
    </span>
  </button>
  <div v-if="isExpanded">
    <slot />
  </div>
</template>

<script>
import ChevronDownSvg from "@/components/svg/ChevronDownSvg";
import ChevronUpSvg from "@/components/svg/ChevronUpSvg"

export default {
  name: "CollapsibleSection",
  components: {
    ChevronDownSvg,
    ChevronUpSvg
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    label: {
      type: String
    },
    buttonClass: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      isExpanded: this.expanded
    }
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style></style>