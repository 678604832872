import { createStore } from "vuex"

export default createStore({
  state() {
    return {
      appError: "",
      hasAppError: false,
      apiCreds: {},
      apiCredsExist: false,
      apiCredsLoading: false,
      apiCredsUnauthorized: false
    }
  },
  mutations: {
    addAppError(state, payload) {
      state.appError = payload.error;
      state.hasAppError = true;
    },
    clearAppError(state) {
      state.appError = "";
      state.hasAppError = false;
    },
    apiCredsStart(state) {
      state.apiCredsLoading = true;
      state.apiCredsUnauthorized = false;
    },
    apiCredsSuccess(state, payload) {
      state.apiCreds = payload.creds;
      state.apiCredsLoading = false;
      if (payload.creds) {
        state.apiCredsExist = true;
      }
    },
    apiCredsError(state, payload) {
      state.appError = payload.error;
      state.hasAppError = true;
      state.apiCredsLoading = false;
    },
    apiCredsUnauthorized(state) {
      state.apiCredsUnauthorized = true;
      state.apiCredsLoading = false;     
    }
  },
  actions: {
    async getApiCredentials({ commit }) {
      commit('apiCredsStart');
      let response = await this.$api.get({
        url: "/apicredentials"
      });
      
      if (response.statusCode === 200) {
        commit('apiCredsSuccess', { creds: response.data })
      } else if (response.statusCode === 403) {
        commit('apiCredsUnauthorized')
      } else {
        commit('apiCredsError', { error: "Sorry, something went wrong when fetching your application credentials." })
      }
    }
  },
  modules: {},
})
