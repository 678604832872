<template>
  <div class="border-b border-gray-lighter">
    <layout-header />
  </div>
  <main class="main-content flex-1 mb-10">
    <slot />
  </main>
</template>

<script>
import Header from "./Header.vue"

export default {
  name: "PageContainer",
  components: {
    "layout-header": Header
  }
}
</script>
